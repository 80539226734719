<template>
  <div>
    <div v-if="organisations && organisations.length">
      <h3 class="flix-html-h3">{{ organisations.length }} {{ $tc('message.organisation', organisations.length) }}</h3>
      <p>Wählen Sie eine Organisation aus, um eine zugeteilte Rolle einzunehmen (grün markiert).</p>
      <p><i>Hinweis: Sie nehmen automatisch die vom zuständigen Admin für Sie zugeteilte Rolle ein. Je nach Rolle (Gast, Mitarbeiter, Manager, Administrator) verfügen Sie über bestimmte Rechte. Klicken Sie erneut auf die Organisation, um die Rolle wieder zu verlassen.</i></p>
      <transition name="flixFadeIn">
        <div v-if="organisations" class="flix-list-group" :key="active">
          <a v-for="(org, index) in organisations" :key="org.ID" href="#" class="flix-list-group-item flix-html-a" :class="{'flix-active': active === org.ID}" @click.prevent="function () { checkOrganisation(index) }">
            <flixIcon id="building" /> <b>{{ org.name }}</b> <span v-if="active === org.ID">({{ $t('message.active') }})</span>
            <br>
            {{ $t('message.role') }}: {{ $t('message.' + org.role) }}
          </a>
        </div>
      </transition>
    </div>
    <div v-else>
      <h3 class="flix-html-h3">{{ $tc('message.organisation', 2) }}</h3>
      <div class="flix-alert flix-alert-danger">
        {{ $tc('message.noEntry', 2) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      organisations: false,
      active: this.$store.state.organisations.organisationActive
    }
  },
  computed: {

  },
  mounted () {
    this.getOrganisations()
  },
  methods: {
    checkOrganisation (index) {
      if (this.$store.state.organisations.organisationActive.toString() === this.organisations[index].ID.toString()) {
        localStorage.removeItem('flix_orga')
        this.$store.commit('setActiveOrganisation')
        this.active = false
        this.reloadPage()
        return false
      }

      const active = btoa(new Date().getTime() + '-' + this.organisations[index].ID)
      localStorage.setItem('flix_orga', active)
      this.$store.commit('setActiveOrganisation', active)
      this.active = this.organisations[index].ID
      this.reloadPage()
    },
    reloadPage () {
      this.$store.commit('setCleanData')
      this.$store.dispatch('getAssistents', function () {
        this.$store.dispatch('getBookings', function () {
          setTimeout(function () {
            this.$router.push({ name: 'signIn' })
          }.bind(this), 1000)
        }.bind(this))
      }.bind(this))
    },
    setOrganisation (ret) {
      this.loaded = true
      this.organisations = ret
    },
    getOrganisations () {
      this.$store.dispatch('getOrganisations', this.setOrganisation)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
